import {XHTTP} from '@/shared/xhttp';
import {LivraisonService} from '@/xhttp';
import {DateHelper} from "@/shared/utils";
import {Tiers} from "./Tiers";
import {Chauffeur} from '@/models/Chauffeur';
import {Facture} from '@/models/Facture';
import {CubageLivraison} from '@/models/CubageLivraison';
import { CubageLivraisonPlateforme } from './CubageLivraisonPlateforme';

@XHTTP(LivraisonService, '/livraisons')
export class Livraison {

	private _id: number;
	private _date: Date = new Date();
	private _chauffeur: Chauffeur;
	private _bl: string;
	private _numfacture: string;
	private _scierie: Tiers;
	private _cubageLivraisons: CubageLivraison[] = [];
	private _cubageLivraisonPlateformes: CubageLivraisonPlateforme[] = [];
	private _prixCamion: number;
	private _prixTransport: number = 0;
	private _multicubage: boolean = false;
	private _facture: Facture;
	
	/////////////
	// Getters //
	/////////////

	public get id(): number {
		return this._id;
	}

	public get date(): Date {
		return this._date;
	}

	public get chauffeur(): Chauffeur {
		return this._chauffeur;
	}

	public get facture(): Facture {
		return this._facture;
	}

	public get bl(): string {
		return this._bl;
	}

	public get numfacture(): string {
		return this._numfacture;
	}

	public get prixCamion(): number {
		return this._prixCamion;
	}

	public get prixTransport(): number {
		return this._prixTransport;
	}

	public get scierie(): Tiers {
		return this._scierie;
	}

	public get cubageLivraisons(): CubageLivraison[] {
		return this._cubageLivraisons;
	}

	public get cubageLivraisonPlateformes(): CubageLivraisonPlateforme[] {
		return this._cubageLivraisonPlateformes;
	}

	public get multicubage(): boolean {
		return this._multicubage;
	}

	/////////
	// Add //
	/////////

	/////////////
	// Setters //
	/////////////

	public set id(value: number) {
		this._id = value;
	}

	public set date(value: Date) {
		this._date = value;
	}

	public set bl(value: string) {
		this._bl = value;
	}

	public set numfacture(value: string) {
		this._numfacture = value;
	}

	public set prixCamion(value: number) {
		this._prixCamion = value;
	}

	public set prixTransport(value: number) {
		this._prixTransport = value;
	}

	public set scierie(value: Tiers) {
		this._scierie = value;
	}
	
	public set chauffeur(value: Chauffeur) {
		this._chauffeur = value;
	}

	public set facture(value: Facture) {
		this._facture = value;
	}

	public set cubageLivraisons(value: CubageLivraison[]) {
		this._cubageLivraisons = value;
	}

	public set cubageLivraisonPlateformes(value: CubageLivraisonPlateforme[]) {
		this._cubageLivraisonPlateformes = value;
	}

	public set multicubage(value: boolean) {
		this._multicubage = value;
	}

	///////////////
	// Serialize //
	///////////////

	public toJSON(): any {
		return {
			id            : this._id,
			date    : this._date ? DateHelper.toLocalizedString(this._date) : null,
			chauffeur  : this._chauffeur ? this._chauffeur.id : null,
			bl	: this._bl,
			numfacture	: this._numfacture,
			scierie         : this._scierie ? this._scierie.id : null,
			cubageLivraisons: this._cubageLivraisons ? this._cubageLivraisons.map(r => r.toJSON()) : [],
			cubageLivraisonPlateformes: this._cubageLivraisonPlateformes ? this._cubageLivraisonPlateformes.map(r => r.toJSON()) : [],
			prixCamion        : this._prixCamion,
			prixTransport        : this._prixTransport,
			multicubage        : this._multicubage,
			facture		: this._facture,
		};
	}

	public fromJSON(data: any): this {
		this._id           = data.id
		this._date         = data.date ? DateHelper.fromLocalizedString(data.date) : null;
		this._chauffeur    = data.chauffeur ? (new Chauffeur()).fromJSON(data.chauffeur) : null;
		this._bl 		   = data.bl;
		this._numfacture 		   = data.numfacture;
		this._scierie      = data.scierie ? (new Tiers).fromJSON(data.scierie) : null;
		this._cubageLivraisons    = data.cubageLivraisons ? data.cubageLivraisons.map(r => (new CubageLivraison()).fromJSON(r)) : [];
		this._cubageLivraisonPlateformes    = data.cubageLivraisonPlateformes ? data.cubageLivraisonPlateformes.map(r => (new CubageLivraisonPlateforme()).fromJSON(r)) : [];
		this._prixCamion     = data.prixCamion;
		this._prixTransport     = data.prixTransport;
		this._multicubage     = data.multicubage;
		this._facture = data.facture
		return this;
	}
}
