
















































































































	import {Component, Vue} from '@/shared/component';
	import {Stored} from "vue-stored-prop-decorator/index";
  import {Contrat, TiersType, User, Plateforme} from '@/models';
	import {Emit} from 'vue-property-decorator';

	import {Tiers, Chantier, Purchase} from '@/models';
	import CrudTiersEditDialog from '@/components/Crud/Tiers/EditDialog.vue';
	import CrudChantierEditDialog from '@/components/Crud/Chantier/EditDialog.vue';
	import CrudPurchaseEditDialog from '@/components/Crud/Purchase/EditDialog.vue';
	import CrudContratEditDialog from '@/components/Crud/Contrat/EditDialog.vue';
	
	import storeUser from '@/stores/modules/user';
	import storeTiers from "@/stores/modules/tiers";
	import CrudPlateformeEditDialog from "@/components/Crud/Plateforme/EditDialog.vue";

	import { version } from '../../../package.json';

	@Component({
		components: {
			CrudTiersEditDialog,
			CrudChantierEditDialog,
      		CrudPurchaseEditDialog,
      		CrudContratEditDialog,
			CrudPlateformeEditDialog,
		}
	})

	export default class NavigationMainMenu extends Vue {

		@Stored(() => storeUser) me!: User;

		private reduce: boolean = false;
		private open: boolean = false;

		private items = [
			{
				route: { name: 'home' },
				title: 'Tableau de bord',
				grant: 'NAV_TABLEAU_BORD',
				icon: 'mdi-view-dashboard',
			},
			{
				title: 'Tiers',
				icon: 'mdi-card-text',
				grant: 'NAV_TIERS',
				children: [
					{
						route: { name: 'proprietaire' },
						title: 'Les propriétaires',
						icon: 'mdi-alpha-t-box',
						grant: 'API_GET_TIERS',
					},
					{
						title: 'Créer un propriétaire',
						icon: 'mdi-alpha-t-box',
						grant: 'API_POST_TIERS',
						clickparameter: 'Proprietaire',
					},
					{ divider: true, inset: true },
					{
						route: { name: 'scierie' },
						title: 'Les scieries',
						icon: 'mdi-alpha-t-box',
						grant: 'API_GET_TIERS',
					},
					{
						title: 'Créer une scierie',
						icon: 'mdi-alpha-t-box',
						grant: 'API_POST_TIERS',
						clickparameter: 'Scierie',
					},
					{ divider: true, inset: true },
					{
						route: { name: 'contact' },
						title: 'Liste des contacts',
						icon: 'mdi-card-text',
						grant: 'API_GET_CONTACT',
					},
				]
			},
			{
				title: 'Contrats',
				icon: 'mdi-hammer-wrench',
				grant: 'NAV_CONTRATS',
				children: [
				{
					route: { name: 'contrat' },
					title: 'Listes des contrats',
					icon: 'mdi-text-box-multiple',
					grant: 'API_GET_CONTRAT',
				},
				{
					title: 'Créer un contrat',
					icon: 'mdi-text-box-plus',
					grant: 'API_POST_CONTRAT',
					clickparameter: 'Contrat',
				},
				{ divider: true, inset: true },
				{
					route: { name: 'reglementsProprietaire' },
					title: 'Réglements propriétaires en attente',
					icon: 'mdi-account-cash-outline',
					grant: 'API_GET_PRESTATION',
				},
				{ divider: true, inset: true },
				{
					route: { name: 'prestation' },
					title: 'Prestations en attente',
					icon: 'mdi-hammer-wrench',
					grant: 'API_GET_PRESTATION',
				},
				{
					route: { name: 'prestations-attentes-cubages' },
					title: 'Cubages estimatifs en attente',
					icon: 'mdi-pine-tree',
					grant: 'API_GET_PRESTATION',
				},
				{
					route: { name: 'prestation-map' },
					title: 'MAP des prestations',
					icon: 'mdi-map-marker-check-outline',
					grant: 'API_GET_PRESTATION',
				},
				]
			},
			{
				title: 'Chantiers',
				icon: 'mdi-account-hard-hat',
				grant: 'NAV_CHANTIERS',
				children: [
					{
						route: { name: 'chantier' },
						title: 'Chantiers en cours',
						icon: 'mdi-account-hard-hat',
						grant: 'NAV_CONTRATS',
					},
					{
						route: { name: 'mes-chantiers' },
						title: 'Mes chantiers en cours',
						icon: 'mdi-account-hard-hat',
						grant: 'API_GET_CHANTIER',
					},
					{
						route: { name: 'chantier_bilan' },
						title: 'Chantiers avec bilan',
						icon: 'mdi-account-hard-hat',
						grant: 'API_GET_PURCHASE',
					},
					{
						title: 'Créer un chantier',
						icon: 'mdi-account-hard-hat',
						grant: 'API_POST_CHANTIER',
						clickparameter: 'Chantier',
					},
					{ divider: true, inset: true },
					{
						route: { name: 'chantier-resteadebarder' },
						title: 'Reste à débarder',
						icon: 'mdi-account-hard-hat',
						grant: 'API_GET_CHANTIER',
					},
					{ divider: true, inset: true },
					{
						route: { name: 'chantier-map' },
						title: 'MAP des chantiers',
						icon: 'mdi-map-marker-check-outline',
						grant: 'API_GET_CHANTIER',
					},
				]
			},
			{
				title: 'Plateformes',
				icon: 'mdi-home-group',
				grant: 'NAV_PLATEFORMES',
				children: [
					{
						route: { name: 'plateforme' },
						title: 'Liste des plateformes',
						icon: 'mdi-text-box-multiple',
						grant: 'API_GET_PLATEFORME',
					},
					{ divider: true, inset: true },
					{
						title: 'Ajouter une plateforme',
						icon: 'mdi-home-group-plus',
						grant: 'API_POST_PLATEFORME',
						clickparameter: 'Plateforme',
					},
				]
			},
			{
				title: 'Livraisons',
				icon: 'mdi-truck-delivery',
				grant: 'NAV_LIVRAISONS',
				children: [
					{
						route: { name: 'suivilivraison' },
						title: 'Suivi des bois à livrer',
						icon: 'mdi-truck-flatbed',
						grant: 'API_GET_CUBAGELIVRAISON',
					},
					{
						route: { name: 'boisrestant-map' },
						title: 'MAP bois restants',
						icon: 'mdi-map-marker-check-outline',
						grant: 'API_GET_CHANTIER',
					},
					{
						route: { name: 'cubagelivraison' },
						title: 'Historique des livraisons',
						icon: 'mdi-truck-delivery',
						grant: 'API_GET_CUBAGELIVRAISON',
					},
					{ divider: true, inset: true },
					{
						route: { name: 'chantier-map' },
						title: 'MAP des chantiers',
						icon: 'mdi-map-marker-check-outline',
						grant: 'API_GET_CHANTIER',
					},
					/*{
						title: 'Ajouter une livraison',
						icon: 'mdi-truck-delivery',
						grant: 'API_POST_LIVRAISON',
						clickparameter: 'Livraison',
					},*/
				]
			},
			/*{
				title: 'Facturation',
				icon: 'mdi-cash-usd-outline',
				grant: 'NAV_FACTURATION',
				children: [
					{
						route: { name: 'purchase' },
						title: 'Factures',
						icon: 'mdi-cash-usd-outline',
						grant: 'API_GET_PURCHASE',
					},
					{
						title: 'Ajouter une facture',
						icon: 'mdi-cash-usd-outline',
						grant: 'API_POST_PURCHASE',
						clickparameter: 'Facture',
					},
				]
			},*/
			{
				title: 'Facturation',
				icon: 'mdi-card-text',
				grant: 'NAV_FACTURES', 
				children: [
					{
						route: { name: 'factures' },
						title: 'Les factures',
						icon: 'mdi-cash-multiple',
						grant: 'API_GET_FACTURE',
					},
					{
						route: { name: 'retards_paiement' },
						title: 'Retard de paiement',
						icon: 'mdi-cash-multiple',
						grant: 'API_GET_FACTURE',
					},
					{ divider: true, inset: true },
					{
						route: { name: 'stats_factures' },
						title: 'Stats factures',
						icon: 'mdi-chart-bar',
						grant: 'API_GET_FACTURE',
					},

				]
			},

			{
				title: 'Paramétrage',
				icon: 'mdi-cog-outline',
				grant: 'NAV_PARAMETRES',
				children: [
					{
						route: { name: 'user' },
						title: 'Les utilisateurs',
						icon: 'mdi-account-multiple',
						grant: 'API_GET_USER',
					},
					{
						route: { name: 'chauffeur' },
						title: 'Les chauffeurs',
						icon: 'mdi-truck-delivery',
						grant: 'API_GET_CHAUFFEUR',
					},
					{
						route: { name: 'abatteur' },
						title: 'Les abatteurs',
						icon: 'mdi-truck-delivery',
						grant: 'API_GET_ABATTEUR',
					},
					{
						route: { name: 'debardeur' },
						title: 'Les débardeurs',
						icon: 'mdi-truck-delivery',
						grant: 'API_GET_DEBARDEUR',
					},
					{
						route: { name: 'prestationType' },
						title: 'Type de prestation',
						icon: 'mdi-cog-outline',
						grant: 'API_GET_PRESTATIONTYPE',
					},
					{
						route: { name: 'boisType' },
						title: 'Type de bois',
						icon: 'mdi-cog-outline',
						grant: 'API_GET_BOISTYPE',
					},
					{
						route: { name: 'boisQuality' },
						title: 'Qualité du bois',
						icon: 'mdi-cog-outline',
						grant: 'API_GET_BOISQUALITY',
					},
					{
						route: { name: 'boisSize' },
						title: 'Taille de bois',
						icon: 'mdi-cog-outline',
						grant: 'API_GET_BOISSIZE',
					},
					{
						route: { name: 'marquage' },
						title: 'Marque du bois',
						icon: 'mdi-cog-outline',
						grant: 'API_GET_MARQUAGE',
					},
					{
						route: { name: 'token' },
						title: 'API Tokens',
						icon: 'mdi-key-variant',
					},
				]
			},

		];

		private onOpen() {
			this.open = true;
			this.reduce = false;
		}

		private onReduce() {
			if (this.open) {
				this.open = false;
			} else {
				this.reduce = true;
			}
		}

		private editProprietaire: Tiers = null;
		private editScierie: Tiers = null;
		private editChantier: Chantier = null;
		private editPurchase: Purchase = null;
		private editContrat: Contrat = null;
		private editPlateforme: Plateforme = null;


		private appVersion = version;

		@Emit()
		private onCreate($parameter): void {
			switch ($parameter) {
  				case 'Proprietaire':
					this.editProprietaire = new Tiers();
					this.editProprietaire.type = TiersType.PROPRIETAIRE;
    			break;
  				case 'Scierie':
					this.editScierie = new Tiers();
					this.editScierie.type = TiersType.SCIERIE;
    			break;
  				case 'Chantier':
					this.editChantier = new Chantier();
    			break;
  				case 'Livraison':
					//this.editLivraison = new Livraison();
    			break;
  				case 'Facture':
					this.editPurchase = new Purchase();
    			break;
				case 'Contrat':
					this.editContrat = new Contrat();
				break;
				case 'Plateforme':
					this.editPlateforme = new Plateforme();
				break;
			}
		}

		private async refreshTiers(): Promise<void> {
			storeTiers.dispatch('getC', { filters: true });
		}

	}
