import {XHTTP} from '@/shared/xhttp';
import {DateHelper} from "@/shared/utils";
import {ReglementType} from '@/models/SuiviReglement';
import {Tiers} from '@/models/Tiers';
import {SuiviAutresReglementService} from '@/xhttp';

@XHTTP(SuiviAutresReglementService, '/suiviautresreglements')
export class SuiviAutresReglement {
	private _id: number;
	private _date: Date = new Date();
	private _montant: number; 
	private _reference: string;
	private _type: ReglementType = ReglementType.Cheque;
	private _tiers: Tiers;

	/////////////
	// Getters //
	/////////////

	public get id(): number {
		return this._id;
	}

	public get date(): Date {
		return this._date;
	}

	public get montant(): number {
		return this._montant;
	}

	public get reference(): string {
		return this._reference;
	}

	public get type(): ReglementType {
		return this._type ? <any>this._type.toString() : null;
	}

	public get tiers(): Tiers {
		return this._tiers;
	}
	
	/////////////
	// Setters //
	/////////////

	public set date(value: Date) {
		this._date = value;
	}

	public set montant(value: number) {
		this._montant = value;
	}

	public set reference(value: string) {
		this._reference = value;
	}

	public set type(value: ReglementType) {
		this._type = value;
	}

	public set tiers(value: Tiers) {
		this._tiers = value;
	}
	
	///////////////
	// Serialize //
	///////////////

	public toJSON(): any {
		return {
			id            : this._id,
			date 		: this._date ? DateHelper.toLocalizedString(this._date) : null,
			montant       : this._montant,
			reference : this._reference,
			type : this._type ? this._type.toString() : null,
			tiers    : this._tiers ? this._tiers.id : null,
		};
	}
	
	public fromJSON(data: any): this {
		this._id           = data.id
		this._date         = data.date ? DateHelper.fromLocalizedString(data.date) : null;
		this._montant        = data.montant;
		this._reference  = data.reference;
		this._type	   = data.type;
		this._tiers     = data.tiers ? (new Tiers()).fromJSON(data.tiers) : null;
		return this;
	}
}
