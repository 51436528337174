import {XHTTP} from '@/shared/xhttp';
import {ContratService} from '@/xhttp';
import {DateHelper} from '@/shared/utils';
import {Tiers} from './Tiers';
import {Document} from "./Document";
import {Prestation} from "./Prestation";
import {User} from './User';

export enum ContratState {
	WAITING = 'WAITING',
	VALIDATE = 'VALIDATE',
	REFUSED = 'REFUSED',
}

@XHTTP(ContratService, '/contrats')
export class Contrat {
	
	private _id: number;
	private _numero: string; 
	private _reference: string;
	private _attestation_annee: number;
	private _attestation_montant_string: string;
	private _attestation_montant: number;
	private _state: ContratState = ContratState.WAITING;
	private _remarques: string;
	private _dtCommand: Date = new Date();
	private _tiers: Tiers;
	private _totalPriceSale: number;
	private _totalPriceBuy: number;
	private _prestations: Prestation[] = [];
	private _documents: Document[] = [];
	private _createdAt: Date;
	private _responsable: User;
	// Uniquement pour la creation rapide d'un chantier + contrat + presta
	private _tag: string;
	private _idTiers: number = 0;
	private _idChantier: number = 0;
	private _idPrestationType: number = 0;
	private _TypeAchat: string = '';
	
	/////////////
	// Getters //
	/////////////
	
	public get id(): number {
		return this._id;
	}
	
	public get numero(): string {
		return this._numero;
	}

	public get reference(): string {
		return this._reference;
	}

	public get attestation_annee(): number {
		return this._attestation_annee;
	}
	
	public get attestation_montant_string(): string {
		return this._attestation_montant_string;
	}

	public get attestation_montant(): number {
		return this._attestation_montant;
	}
	
	public get state(): ContratState {
		return this._state;
	}
	
	public get remarques(): string {
		return this._remarques;
	}

	public get dtCommand(): Date {
		return this._dtCommand;
	}
	
	public get tiers(): Tiers {
		return this._tiers;
	}
	
	public get responsable(): User {
		return this._responsable;
	}
	
	public get createdAt(): Date {
		return this._createdAt;
	}

	public get totalPriceSale(): number {
		return this._totalPriceSale;
	}

	public get totalPriceBuy(): number {
		return this._totalPriceBuy;
	}

	public get documents(): Document[] {
		return this._documents;
	}

	public get prestations(): Prestation[] {
		return this._prestations;
	}

	/////////////
	// Setters //
	/////////////
	
	public set idTiers(value: number) {
		this._idTiers = value;
	}

	public set idChantier(value: number) {
		this._idChantier = value;
	}

	public set idPrestationType(value: number) {
		this._idPrestationType = value;
	}

	public set TypeAchat(value: string) {
		this._TypeAchat = value;
	}

	public set tag(value: string ) {
		this._tag = value;
	}

	public set numero(value: string) {
		this._numero = value;
	}

	public set reference(value: string) {
		this._reference = value;
	}

	public set attestation_annee(value: number) {
		this._attestation_annee = value;
	}

	public set attestation_montant_string(value: string) {
		this._attestation_montant_string = value;
	}

	public set attestation_montant(value: number) {
		this._attestation_montant = value;
	}

	public set state(value: ContratState) {
		this._state = value;
	}
	
	public set remarques(value: string) {
		this._remarques = value;
	}

	public set dtCommand(value: Date) {
		this._dtCommand = value;
	}
	
	public set tiers(value: Tiers) {
		this._tiers = value;
	}
	
	public set responsable(value: User) {
		this._responsable = value;
	}
	
	public set createdAt(value: Date) {
		this._createdAt = value;
	}

	public set totalPriceSale(value: number) {
		this._totalPriceSale = value;
	}

	public set totalPriceBuy(value: number) {
		this._totalPriceBuy = value;
	}
	
	/////////
	// Add //
	/////////

	public addDocuments(document: Document): this {
		document.contrat = this;
		if (this._documents.indexOf(document) === -1) {
			this._documents.push(document);
		}
		return this;
	}

	public removeDocuments(document: Document): this {
		document.contrat = this;
		const index = this._documents.indexOf(document);
		if (index !== -1) {
			this._documents.splice(index, 1);
		}
		return this;
	}
	
	///////////////
	// Serialize //
	///////////////

	public toJSON(): any {
		return {
			id            : this._id,
			numero        : this._numero,
			reference     : this._reference,
			attestation_annee     : this._attestation_annee,
			attestation_montant_string     : this._attestation_montant_string,
			attestation_montant     : this._attestation_montant,
			state         : this._state,
			remarques     : this._remarques,
			dtCommand     : this._dtCommand ? DateHelper.toLocalizedString(this._dtCommand) : null,
			tiers         : this._tiers ? this._tiers.id : null,
			responsable         : this._responsable ? this._responsable.id : null,
			prestations	  : this._prestations ? this._prestations.map(p => p.toJSON()) : [],

			tag  : this._tag,
			idTiers  : this._idTiers,
			idPrestationType  : this._idPrestationType,
			typeAchat  : this._TypeAchat,
			idChantier  : this._idChantier
		};
	}

	public fromJSON(data: any): this {
		this._id             = data.id;
		this._numero         = data.numero;
		this._reference      = data.reference;
		this._attestation_annee      = data.attestation_annee;
		this._attestation_montant_string      = data.attestation_montant_string;
		this._attestation_montant      = data.attestation_montant;
		this._state          = data.state;
		this._remarques      = data.remarques;
		this._dtCommand      = data.dtCommand ? DateHelper.fromLocalizedString(data.dtCommand) : null;
		this._tiers          = data.tiers ? (new Tiers()).fromJSON(data.tiers) : null;
		this._responsable          = data.responsable ? (new User()).fromJSON(data.responsable) : null;
		this._createdAt      = data.createdAt ? new Date(data.createdAt) : null;
		this._totalPriceSale = data.totalPriceSale;
		this._totalPriceBuy  = data.totalPriceBuy;
		this._prestations    = data.prestations ? data.prestations.map(p => (new Prestation()).fromJSON(p)) : [];
		if (data.documents)  {
			for (const d of data.documents) {
				this.addDocuments((new Document()).fromJSON(d));
			}
		}
		return this;
	}
}
